import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';

import man from '../../resources/img/About/man.jpg'

import './About.scss'
import SvgMap from '../SvgMap/SvgMap';
import Tab from '../Tab/Tab';

export default function AboutPage() {
    const [t] = useTranslation()

    return (
        <section className='about'>

            <div className='about__header'>
                <div className='about__overlay' >
                    <Container>
                        <span className="line-title text-white">SIMEX</span>
                        <h1>
                            {t("about-title1")}
                        </h1>
                    </Container>
                </div>
            </div>

            <Container className='my-5'>
                <Row className='flex-wrap-reverse mb-5 overflow-hidden'>
                    <Col xs={4} md={2} >
                        <div className='aboutsection__img1' />
                    </Col>

                    <Col xs={8} md={5}>
                        <div className="aboutsection__slider">
                            <img src={man} alt="?" />
                        </div>
                        <Row>
                            <Col md={3}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={6}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("+")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className='aboutsection__name'>{t("about1")}</span>
                            </Col>
                            <Col xs md={4}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={2800}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className='aboutsection__name'>{t("about2")}</span>
                            </Col>
                            <Col xs md={4}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={25}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("about-mln")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className='aboutsection__name'>{t("about3")}</span>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs md={5} className="aboutsection__text">
                        <div className="ps-3">
                            <span className="line-title">
                                {t("about-sup")}
                            </span>

                            <h2>
                                SIMEX PROGRESS – {t("about-title")}
                            </h2>

                            <p>
                                {t("about-text1")}
                            </p>

                            <p>
                                {t("about-text2")}
                            </p>

                        </div>
                    </Col>
                </Row>

                <Row className='about__mission overflow-hidden' >
                    <Col data-aos="flip-up" xs md={6} lg={3}>
                        <div className='about__mission-box'>
                            <h3>
                                {t("mission-title1")}
                            </h3>

                            <p>
                                {t("mission-text1")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" xs md={6} lg={3}>
                        <div className='about__mission-box'>
                            <h3>
                                {t("mission-title2")}
                            </h3>

                            <p>
                                {t("mission-text2")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" xs md={6} lg={3}>
                        <div className='about__mission-box'>
                            <h3>
                                {t("mission-title3")}
                            </h3>

                            <p>
                                <b>{t("mission-title3.1")}</b>  {t("mission-text3")}
                            </p>
                            <p>
                                <b>{t("mission-title3.2")}</b>  {t("mission-text3.1")}
                            </p>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" xs md={6} lg={3}>
                        <div className='about__mission-box'>
                            <h3>
                                {t("mission-title4")}
                            </h3>
                            <p>
                                {t("mission-text4")}
                            </p>
                        </div>
                    </Col>
                </Row>

                <Tab />

                <SvgMap />
            </Container>

        </section>
    )
}
