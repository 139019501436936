import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";

import './AdvantageSection.scss'

function AdvantageSection() {
    const { t } = useTranslation()

    return (
        <section className='advantage py'>
            <Container>
                <Row>
                    <Col data-aos="flip-up" md={3}>
                        <div className="advantage__box">

                            <div className='advantage__box-text'>
                                <h2>
                                    {t("advantage-title1")}
                                </h2>
                                <p>
                                    {t("advantage-text1")}
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" md={3}>
                        <div className="advantage__box">
                            <div className='advantage__box-text'>
                                <h2>
                                    {t("advantage-title2")}
                                </h2>
                                <p>
                                    {t("advantage-text2")}
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" md={3}>
                        <div className="advantage__box">
                            <div className='advantage__box-text'>
                                <h2>
                                    {t("advantage-title3")}
                                </h2>
                                <p>
                                    {t("advantage-text3")}
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col data-aos="flip-up" md={3}>
                        <div className="advantage__box">
                            <div className='advantage__box-text'>
                                <h2>
                                    {t("advantage-title4")}
                                </h2>
                                <p>
                                    {t("advantage-text4")}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AdvantageSection;