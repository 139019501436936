import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';
import ScrollTop from "../ScrollTop/ScrollTop";
import ScrollToTop from 'react-scroll-to-top';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import MainPage from '../Pages';
import AboutPage from '../Pages/About';
import ProductsPage from '../Pages/ProductsPage';
import ContactsPage from '../Pages/ContactsPage';
import Page404 from '../404/404';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import '../../styles/_main.scss';
import '../../styles/Buttons.scss';
import '../../styles/Forms.scss';
import '../../styles/Various.scss';

function App() {
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'ru')

    return (
        <Router>
            <LanguageContext.Provider value={[language, setLanguage]}>
                <Header />
                <main>
                    <ScrollTop>
                        <Routes>
                            <Route exact path="/" element={<MainPage />} />
                            <Route exact path="/about" element={<AboutPage />} />
                            <Route exact path="/products" element={<ProductsPage />} />
                            <Route exact path="/contacts" element={<ContactsPage />} />
                            <Route path="*" element={<Page404 />} />
                        </Routes>
                    </ScrollTop>
                </main>
                <ScrollToTop smooth top="400" color="#291258" />
                <Footer />
            </LanguageContext.Provider>
        </Router>
    );
}

export default App