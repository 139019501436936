import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import manTalk from '../../resources/img/Contacts/man.jpg'

import MyForm from '../Form/MyForm';

import "./ContactsSection.scss";

function ContactsSection(props) {
    const { t } = useTranslation()

    return (
        <section className='contactssection py'>
            <Container className='my-5'>
                <Row>
                    <Col data-aos="fade-right" md={6}>
                        <img src={manTalk} className='px-5' alt="?" />
                    </Col>

                    <Col data-aos="fade-left" md={6}>
                        <MyForm />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactsSection