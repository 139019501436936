import AboutSection from "../Sections/AboutSection";
import AdvantageSection from "../Sections/AdvantageSection";
import IntroSection from "../Sections/IntoSection";
import ProductSection from "../Sections/ProductSection";
import NewsSection from "../Sections/NewsSection";
import PartnersSection from "../Sections/PartnersSection";
import ContactsSection from "../Sections/ContactsSection";


export default function MainPage() {
    
    return (
        <>
            <IntroSection />
            <AdvantageSection />
            <AboutSection />
            <ProductSection />
            {/* <NewsSection /> */}
            <PartnersSection />
            <ContactsSection />
        </>
    )
}