import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { HashLink } from 'react-router-hash-link';

import pic1 from '../../resources/img/ProductsPage/women1.1.jpg'
import pic2 from '../../resources/img/ProductsPage/man1.jpg'
import pic4 from '../../resources/img/ProductsPage/boy1.jpg'

import "./ProductSection.scss";

function ProductSection() {
    const { t } = useTranslation()

    return (
        <section className='productsection py'>
            <Container>
                <span className="line-title">{t("prod-section-sup")}</span>

                <div className='productsection__title'>
                    <h2>
                    {t("prod-section-title")}
                    </h2>
                </div>

                <Row className='overflow-hidden'>
                    <Col data-aos="fade-up" md={4} className="productcol1">
                        <div className='productsection__box'>
                            <img src={pic1} alt="?" />
                            <p>
                            {t("prod-section-women")}
                            </p>
                            <HashLink to="/products#women" className='button'>{t("more")} <ArrowRightAltIcon /></HashLink >
                        </div>
                    </Col>

                    <Col data-aos="fade-up" md={4} className="productcol2">
                        <div className='productsection__box'>
                            <img src={pic2} alt="?" />

                            <p>
                            {t("prod-section-men")}
                            </p>
                            <HashLink to="/products#men" className='button'>{t("more")}<ArrowRightAltIcon /> </HashLink>
                        </div>
                    </Col>

                    <Col data-aos="fade-up" md={4} className="productcol3">
                        <div className='productsection__box'>
                            <img src={pic4} alt="?" />

                            <p>
                            {t("prod-section-child")}
                            </p>
                            <HashLink smooth to="/products#kid" className='button'>{t("more")}<ArrowRightAltIcon /></HashLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ProductSection