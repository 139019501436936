import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useTranslation } from "react-i18next";
import MyForm from "../Form/MyForm";

import { Col, Container, Row } from "react-bootstrap";
import "./ContactsPage.scss";

function ContactsPage() {
  const { t } = useTranslation();

  return (
    <section className="contacts">
      <div className="contacts__header">
        <div className="contacts__overlay">
          <Container>
            <span className="line-title text-white">SIMEX</span>
            <h1>{t("contact-sup")}</h1>
          </Container>
        </div>
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col md={4} className="contacts__box">
            <PhoneIcon />
            <br />
            <h3>{t("number")}</h3>
            <a href="tel:+998909555570">+99890 955 55 70</a>
            <a href="tel:+998712319803">+99871 231 98 03</a>
            <a href="tel:+998712319804">+99871 231 98 04</a>
          </Col>

          <Col md={4} className="contacts__box">
            <LocationOnIcon />
            <br />
            <h3>{t("address")}</h3>
            <p>{t("address-name")}</p>
          </Col>

          <Col md={4} className="contacts__box">
            <EmailIcon />
            <br />
            <h3>{t("email")}</h3>
            <p>info@simex.uz</p>
          </Col>

          <Col md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1499.914216090432!2d69.26011923685871!3d41.2472949845795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8f3cbd27088dcfae!2zNDHCsDE0JzQ4LjEiTiA2OcKwMTUnMzUuMCJF!5e0!3m2!1sru!2s!4v1658323084305!5m2!1sru!2s"
              width="90%"
              height="550"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
          <Col>
            <MyForm />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ContactsPage;
