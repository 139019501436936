import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import women22 from '../../resources/img/ProductsPage/women2.2.jpg'
import man from '../../resources/img/ProductsPage/man.jpg'
import boy2 from '../../resources/img/ProductsPage/boy2.jpg'

import './ProductsPage.scss'

export default function ProductsPage() {
    const [t] = useTranslation()

    return (
        <section className='products'>

            <div className='products__header'>
                <div className='products__overlay' >
                    <Container>
                        <span className="line-title text-white">SIMEX</span>
                        <h1>
                            {t("product-title")}
                        </h1>
                    </Container>
                </div>
            </div>

            <Container className='my-5 overflow-hidden'>
                <h4>
                    {t("product-text")}
                </h4>
                <Row>
                    <a id="women" className='line-header' />

                    <Col md={6} data-aos="fade-right" data-aos-duration="2000">
                        <h3 className='products__title ps-5'>{t("product-women")}</h3>
                        <p className='products__text'>
                            {t("product-women-text")}
                        </p>
                    </Col>
                    <Col
                        data-aos="fade-left"
                        data-aos-duration="2000"
                        md={6}
                        className="products__img">
                        <img width="300" height="470" src={women22} alt="img" />
                    </Col>
                </Row>
                <Row>
                    <a id="men" className='line-header' />

                    <Col data-aos="fade-right"
                        data-aos-duration="2000"
                        md={6}
                        className="products__img">

                        <img width="300" height="470" src={man} alt="img" />
                    </Col>
                    <Col md={6} data-aos="fade-left" data-aos-duration="2000">
                        <h3 className='products__title text-end pe-5 me-5'>{t("product-men")}</h3>
                        <p className='products__text'>
                            {t("product-men-text")}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <a id="kid" className='line-header' />

                    <Col md={6} data-aos="fade-right" data-aos-duration="2000">
                        <h3 className='products__title ps-5'>{t("product-child")}</h3>
                        <p className='products__text'>
                            {t("product-child-text")}
                        </p>
                    </Col>
                    <Col
                        data-aos-duration="2000"
                        data-aos="fade-left"
                        md={6}
                        className="products__img">
                        <img width="300" height="470" src={boy2} alt="img" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
