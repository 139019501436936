import { Col, Row } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';

import proizdvodstvo from '../../resources/img/About/Tabs/proizvodstvo.jpg'
import zokroyniy1 from '../../resources/img/About/Tabs/Ленточные ножи2.1.jpg'
import zokroyniy2 from '../../resources/img/About/Tabs/Настилочная машина2.2.jpg'
import eksperement1 from '../../resources/img/About/Tabs/настилочный плоттер3.1.jpg'
import eksperement2 from '../../resources/img/About/Tabs/экспериментальный отдел3.2.jpg'
import eksperement3 from '../../resources/img/About/Tabs/Экспериментальный плоттер3.3.jpg'
import shveyniy1 from '../../resources/img/About/Tabs/Швейный цех4.1.jpg'
import shveyniy2 from '../../resources/img/About/Tabs/Швейный цех4.2.jpg'
import shveyniy3 from '../../resources/img/About/Tabs/Швейный цех4.3.jpeg'


import './Tab.scss'

function Tabs() {
    const { t } = useTranslation()

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div id="all" className="tabs__component">
            <h2 className='line-title fs-2'>{t("tab-title")}</h2>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList scrollButtons="auto" centered onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={t("tab1")} value="1" />
                            <Tab label={t("tab2")} value="2" />
                            <Tab label={t("tab3")} value="3" />
                            <Tab label={t("tab-title")} value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Row>
                            <Col md={4}>
                                <img src={eksperement1} alt="настилочный плоттер" />
                            </Col>
                            <Col md={4}>
                                <img src={eksperement2} alt="экспериментальный отдел" />
                            </Col>
                            <Col md={4}>
                                <img src={eksperement3} alt="Экспериментальный плоттер" />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value="2">
                        <Row className='justify-content-center'>
                            <Col md={4}>
                                <img src={zokroyniy1} alt="Ленточные ножи" />
                            </Col>
                            <Col md={4}>
                                <img src={zokroyniy2} alt="Настилочная машина" />
                            </Col>
                        </Row>

                    </TabPanel>

                    <TabPanel value="3">
                        <Row>
                            <Col md={4}>
                                <img src={shveyniy1} alt="Экспериментальный плоттер" />
                            </Col>
                            <Col md={4}>
                                <img src={shveyniy2} alt="Экспериментальный плоттер" />
                            </Col>
                            <Col md={4}>
                                <img src={shveyniy3} alt="Экспериментальный плоттер" />
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value="4" className='d-flex justify-content-center'>
                        <Col md={4}>
                            <img src={proizdvodstvo} alt="Производсто" />
                        </Col>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}

export default Tabs