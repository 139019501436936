import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import bg from "../../resources/img/ProductsPage/kids2.jpg"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AOS from "aos";

import "./IntroSection.scss";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function IntroSection() {
    const { t } = useTranslation()

    useEffect(() => {
        AOS.init({
            duration: 1000
        });
    }, []);

    return (
        <section className="intro py mainTop">
            <Container>
                <Row className="overflow-hidden">
                    <Col data-aos="zoom-in" md={6} className="intro__left">
                        <span className="line-title">{t("intro-sup")}</span>

                        <h1>
                            SIMEX PROGRESS -
                            {t("intro-title")}
                        </h1>

                        <p>
                            {t("intro-sub")}
                        </p>

                        <Link to="/about" className="button">{t("more")} <ArrowRightAltIcon /> </Link>
                    </Col>

                    <Col data-aos="zoom-in" md={6} className="intro__right">
                        <img src={bg} alt="?" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default IntroSection;