import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import concept from '../../resources/img/Partners/CC.png'
import acoola from '../../resources/img/Partners/acoola.png'
import artie from '../../resources/img/Partners/artie.png'
import DM from '../../resources/img/Partners/DM.png'
import melon from '../../resources/img/Partners/melon.png'
import sela from '../../resources/img/Partners/sela.png'
import ut from '../../resources/img/Partners/ut.png'


import "./PartnersSection.scss";

function PartnersSection() {
    const { t } = useTranslation()

    return (
        <section className='partnerssection py'>
            <Container>
                <h2 className='line-title mb-4 fs-1'>{t("partners")}</h2>
                <Row>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={acoola} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={concept} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={artie} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={DM} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={melon} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={sela} alt="?" />
                    </Col>
                    <Col xs={6} md={3} className="partnerssection__box">
                        <img src={ut} alt="?" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PartnersSection