import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import man from '../../resources/img/About/Tabs/proizvodstvo.jpg'

import "./AboutSection.scss";

function AboutSection() {
    const { t } = useTranslation()

    return (
        <section className="aboutsection py">
            <Container>
                <Row className="flex-wrap-reverse">
                    <Col xs md={6}>
                        <div className="aboutsection__slider">
                            <img src={man} alt="?" />
                        </div>
                        <Row>
                            <Col xs md={5}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={1000000}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("qualitysht")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className="aboutsection__name">{t("quality1")}</span>
                            </Col>
                            <Col xs md={4}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={150}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("+")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className="aboutsection__name">{t("quality2")}</span>
                            </Col>
                            <Col xs md={3}>
                                <h3 className="aboutsection__counter">
                                    <CountUp
                                        end={200}
                                        duration={2.75}
                                        useEasing={true}
                                        useGrouping={true}
                                        separator=" "
                                        suffix={t("+")}
                                    >
                                        {({ countUpRef, start }) => (
                                            <VisibilitySensor onChange={start} delayedCall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                </h3>
                                <span className="aboutsection__name">{t("quality3")}</span>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs md={6} className="aboutsection__text">
                        <div className="ps-3">
                            <span className="line-title">
                                {t("quality-sup")}
                            </span>

                            <h2>
                                {t("quality-title")}
                            </h2>

                            <p>
                                {t("quality-sub")}
                            </p>

                            <HashLink to="/about#all" className="button">{t("more")} <ArrowRightAltIcon /> </HashLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutSection;