import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

import { Form, Formik } from "formik";

import { useTranslation } from "react-i18next";
import "./MyForm.scss";

export const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField
        {...props}
        {...field}
        label={label}
        variant="standard"
        error={meta.touched && meta.error}
        fullWidth
      />
      {meta.touched && meta.error ? (
        <i className="error">{meta.error}</i>
      ) : null}
    </>
  );
};

function MyForm(props) {
  const [t] = useTranslation();
  const [loading, setLoading] = React.useState(false);
  return (
    <div className="my-form">
      <span className="line-title">{t("contact-sup")}</span>

      <h2> {t("contact-title")}</h2>

      <Formik
        initialValues={{
          name: "",
          email: "",
          number: "",
          description: "",
        }}
        onSubmit={async (data, form) => {
          try {
            setLoading(true);
            setLoading(true);
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("email", data.email);
            formData.append("message", data.description);
            formData.append("phone", data.number);

            const response = await fetch("/mailer/", {
              method: "POST",
              body: formData,
              next: {
                revalidate: 0,
              },
            });

            if (response.ok) {
              form.resetForm();
            }
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ values, resetForm, errors, handleChange, handleBlur }) => (
          <Form>
            <Row>
              <Col xs={12} className="mb-4">
                <MyTextInput label={t("contact-name")} name="name" required />
              </Col>
              <Col xs={6} className="mb-4">
                <MyTextInput
                  label={t("contact-phone")}
                  name="number"
                  required
                  type="tel"
                />
              </Col>
              <Col xs={6} className="mb-4">
                <MyTextInput label="Email" name="email" required type="email" />
              </Col>
              <Col xs={12} className="mb-4">
                <MyTextInput
                  label={t("contact-message")}
                  name="description"
                  rows={3}
                  multiline
                />
              </Col>
              <div>
                <button
                  type="submit"
                  className="button button_enter"
                  disabled={loading}
                >
                  {t("contact-send")}
                </button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default MyForm;
